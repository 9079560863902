.stories {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.button-story {
    position: absolute;
    bottom: 1%;
    width: 70%;
    left: 50%;
    z-index: 15000;
    transform: translate(-50%, -50%);
}

.white-button {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(26, 26, 26, 1);
}

@media (min-width: 850px) {
    .stories {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background-size: cover;
    }

    .button-story {
        bottom: 2%;
    }
}
