.story-item {
    position: relative;
    border-radius: 16px;
    background-color: var(--color-background-secondary);
    padding: 24px 20px 60px 20px;
    cursor: pointer;
}

.story-icon {
    position: absolute;
    border-radius: 100px;
    padding: 10px;
    background-color: var(--color-background-primary);
    bottom: 5%;
    right: 2%;
}

.modal-container div:nth-child(2) > div {
    width: 100%;
}
