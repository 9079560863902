.container {
    padding-left: 16px;
    padding-right: 16px;
    max-width: 90%;
    margin: auto;
}

.envelop-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 140px;
}

.image-block {
    position: relative;
    padding-top: 50px;
}

.heading {
    font-size: 42px;
    line-height: 42px;
    letter-spacing: 2%;
}

.title {
    width: 100%;
}

.envelop-image {
    margin: auto;
    width: 250px;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.box-shadow {
    height: 3%;
    width: 20%;
    position: absolute;
    box-shadow: 0px 0px 88px 100px rgba(255, 225, 204, 1);
    top: 50%;
    left: 50%;
    z-index: -10;
    transform: translate(-50%, -50%);
}

.messages-typography {
    max-width: 645px;
}

.message-block {
    background-color: rgba(244, 244, 244, 1);
    border-radius: 20px;
    max-width: 100%;
}

.messages-line-container {
    background-image: url('/line.png');
    background-repeat: repeat no-repeat;
    background-size: 100%;
    padding-top: 70px;
    padding-bottom: 100px;
}

.stories-block {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
    padding-bottom: 22px;
}

.steps-block {
    padding-top: 50px;
    padding-bottom: 50px;
}

.grid-steps {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
}

.step-item {
    padding: 24px 20px 20px 0;
}

.badge {
    max-width: max-content;
    background-color: rgba(247, 247, 247, 1);
    border-radius: 400px;
    padding: 8px 16px;
    margin-bottom: 24px;
}

.step-header {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    margin-bottom: 14px;
}

.popular-products-block {
    padding-top: 34px;
    padding-bottom: 34px;
    display: flex;
    flex-direction: column;
}

.popular-products {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 40px;
    margin-top: 40px;
    justify-content: center;
}

.button-product {
    max-width: max-content;
    align-self: center;
}

.photo-block {
    padding-top: 70px;
    padding-bottom: 100px;
    background-color: rgba(244, 244, 244, 1);
    border-radius: 20px;
    max-width: 100%;
}

.photo-line-container {
    background-image: url('/line.png');
    background-repeat: repeat;
}

.photo-block-content {
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
}

.photo-content-image {
    width: auto;
    height: 600px;
    z-index: 10;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.title-container {
    width: 100%;
}

.mobile-photo-container {
    position: relative;
}

.arrow-photo {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 79px;
    height: 100px;
    right: 1%;
    top: -5%;
}

.mobile-photo {
    width: 90vw;
    height: 240px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.line-image {
    width: 1200px;
    height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    transform: translate(-50%, -50%);
}

.partners-line {
    padding-top: 40px;
    padding-bottom: 40px;
}

.animation {
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: 20px;
}

.animation:before,
.animation:after {
    content: '';
    height: 100%;
    top: 0;
    width: 10%;
    position: absolute;
    z-index: 1;
    pointer-events: none;
}
.animation:before {
    left: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
.animation:after {
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.animation-content {
    flex-shrink: 0;
    display: flex;
    gap: 30px;
    counter-reset: item;
    justify-content: space-around;
    min-width: 100%;
    animation: animation 30s linear infinite;
}

.partner-badge {
    background-color: rgba(247, 247, 247, 1);
    border-radius: 400px;
    padding: 12px 16px;
    width: content-box;
}

@keyframes animation {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - 20px));
    }
}

@media (min-width: 850px) {
    .container {
        max-width: 1440px;
        margin: auto;
        padding-right: 120px;
        padding-left: 120px;
        padding-top: 76px;
    }

    .envelop-block {
        flex-direction: row;
    }

    .text-block {
        width: 55%;
    }

    .image-block {
        width: 45%;
        padding-top: 0;
    }

    .heading {
        font-size: 52px;
        line-height: 52px;
    }

    .title {
        width: 50%;
    }

    .envelop-image {
        width: 450px;
        height: 380px;
    }

    .box-shadow {
        width: 50%;
    }

    .message-block {
        max-width: 1440px;
        margin: auto;
    }

    .stories-block {
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
        padding-bottom: 52px;
    }

    .steps-block {
        padding-top: 80px;
        padding-bottom: 100px;
    }

    .grid-steps {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .popular-products-block {
        padding-top: 64px;
        padding-bottom: 64px;
        text-align: center;
        align-items: center;
    }

    .photo-block {
        padding-top: 100px;
        max-width: 1440px;
        margin: auto;
    }

    .photo-block-content {
        padding-right: 120px;
        padding-left: 120px;
    }

    .title-container {
        width: 60vw;
    }

    .partners-line {
        max-width: 1440px;
        margin: auto;
    }
}
